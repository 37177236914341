import React from "react";
import axios from "axios";
import Loading from "../assets/Loading";
import {MdClose, MdSave, MdDelete, MdNavigateBefore, MdNavigateNext} from "react-icons/md";
import {Scrollbars} from "react-custom-scrollbars";

let uploadImages = [];

class Property extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            configuration: [],
            loading: true,
            property:{},
            images: [],
            preview: false,
            dragStartId: false,
            dragOverId: false,
            uploadImages: [],
            deleting: false
        };
    }

    componentDidMount() {
        this.fetchConfiguration();
    }

    deleteImage(id, nr){
        axios.post(`${this.props.apiPath}`,
            {
                type: "deleteImage",
                data: {
                    nr: nr,
                    id: id
                }
            },
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            })
            .then(res => {
                this.props.showToast("success", "Erfolg", `Bild erfolgreich gelöscht.`)
                this.setState({
                    deleting: false
                })
            })
            .catch(error =>
                this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Speichern des Bildes aufgetreten. ${error}`)
            );
    }
    orderImages(start, end){
        axios.post(`${this.props.apiPath}`,
            {
                type: "orderImages",
                data: {
                    start: start,
                    end: end,
                    id: this.props.property.id
                }
            },
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            })
            .then()
            .catch(error =>
                this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Speichern des Bildes aufgetreten. ${error}`)
            );
    }

    addImages(){
        axios.post(`${this.props.apiPath}`,
            {
                type: "addImage",
                data: {
                    image: uploadImages[0],
                    id: this.props.property.id
                }
            },
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            })
            .then(res => {
                if(uploadImages.length >= 1){
                    this.setState({
                        images:[
                            ...this.state.images,
                            uploadImages[0]
                        ]
                    });
                    uploadImages.shift();
                    if(uploadImages[0]){
                        this.addImages();
                    }else{
                        this.props.showToast("success", "Erfolg", `Bild erfolgreich hochgeladen.`)
                        this.setState({
                            images:[
                                ...this.state.images,
                                ...this.state.uploadImages
                            ],
                            uploadImages: []
                        });
                    }
                }else{
                    this.props.showToast("success", "Erfolg", `Bild erfolgreich hochgeladen.`)
                    this.setState({
                        images:[
                            ...this.state.images,
                            ...this.state.uploadImages
                        ],
                        uploadImages: []
                    });
                }
            })
            .catch(error =>
                this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Speichern des Bildes aufgetreten. ${error}`)
            );
    }
    saveProperty(action, id){
        axios.post(`${this.props.apiPath}`,
            {
                type: "property",
                data: {
                    action: action,
                    property: {...this.state.property},
                    id: id
                }
            },{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then(res => {
                if(res.data){
                    this.props.showToast("success", "Erfolg", "Die Immobilie wurde erfolgreich gespeichert.")
                }
                this.props.closeProperty();
            })
            .catch(error =>
                this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Speichern der Immobilie aufgetreten. ${error}`)
            );
    }

    async fetchProperty(id){
        const url = `${this.props.apiPath}property&id=${id}`;
        let result = [];
        await axios.get(url,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                result = {
                    content: JSON.parse(res.data[0].content),
                    images: JSON.parse(res.data[0].images)
                };
            })
            .catch(error => this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Laden der Immobilie aufgetreten. ${error}`));
        return result;
    };
    fetchConfiguration(){
        const url = `${this.props.apiPath}configuration`;
        axios.get(url,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                const data = JSON.parse(res.data[0].content);
                const property = {};
                for (let i = 0; i < data.length; i++) {
                    property[data[i].key] = {
                        value: "",
                        unit: ""
                    };
                }
                return({
                    configuration: data, loading: false, property: property
                })
            }).then(async configuration =>{
                if(this.props.property.id){
                    const editProperty = await this.fetchProperty(this.props.property.id);
                    configuration.property = editProperty.content;
                    configuration.images = editProperty.images;
                }
                return configuration;
            }).then(result => {
                this.setState({
                    ...result
                })
        })
            .catch(error => this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Laden der Konfiguration aufgetreten. ${error}`));
    };

    updateItem(e, name, unit){
        this.setState({
            property: {
                ...this.state.property,
                [name]: {
                    value: e.currentTarget.value,
                    unit: unit ? unit : ""
                }
            }
        })
    }

    readAsDataURL(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = function() {
                resolve(fr.result);
            }
            fr.readAsDataURL(file);
        });
    }

    handleImageChange(e) {
        e.preventDefault();
        let files = [];
        for(const file of e.target.files){
            files.push(file);
        }
        Promise.all(Array.prototype.map.call(files, this.readAsDataURL))
            .then(images => {
                uploadImages = images;
                this.setState({
                    uploadImages: images
                });
                this.addImages()
            })
            .catch(error => this.props.showToast("error", "Fehler", `Beim Aktualisieren der Bilder ist ein Fehler aufgetreten. ${error}`));
    }

    async removeImage(i, id){
        this.setState({
            deleting: true
        })
        const {images} = this.state;
        images.splice(i,1);
        await this.deleteImage(id, i)
        this.setState({
            images: images
        })
    }

    arrayMove(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    startDrag(e, i) {
        this.setState({dragStartId: i})
    }

    reArrange(e) {
        e.preventDefault();
        e.stopPropagation();
        const {images, dragStartId, dragOverId} = this.state;
        const newImages = this.arrayMove(images, dragStartId, dragOverId);
        this.orderImages(dragStartId, dragOverId);
        this.setState({
            images: newImages, dragStartId: false, dragOverId: false
        })

    }

    render(){
        const {configuration, loading, property, images, preview, dragStartId, dragOverId} = this.state;
        const items = configuration.map((item, i) => {
            return (
                <div className="item" key={"item-"+i}>
                    <label>
                        <span>{item.name}</span>
                        {
                            item.type === "text" ?
                                <input type="text" value={property[item.key] ? property[item.key].value : ""} onChange={e => this.updateItem(e, item.key, item.unit)}/>
                                :
                                <textarea value={property[item.key] ? property[item.key].value : ""} onChange={e => this.updateItem(e, item.key, item.unit)}/>
                        }
                        {
                            item.unit && item.unit !== "" ?
                                <span className="unit">{item.unit}</span>:""
                        }
                    </label>
                </div>
            )
        })
        return (
            <div className="property modal">
                <div className="head">
                    <div className="left">
                        <button onClick={() => this.saveProperty(this.props.property.type, this.props.property.id)}>Speichern & Schließen<MdSave/></button>
                    </div>
                    <div className="right">
                        <button onClick={() => this.props.closeProperty()}>Schließen <MdClose/></button>
                    </div>
                </div>
                {
                    preview && preview.image && images ?
                    <div className="preview-wrapper">
                        <div className="close" onClick={() => this.setState({preview: false})}><MdClose/></div>
                        <div className="preview" style={{backgroundImage: `url(${preview.image})`}}/>
                        <div className={"prev"+(preview.nr === 0 ? " disabled":"")}
                             onClick={() => preview.nr > 0 ? this.setState({preview: {nr: preview.nr-1, image: images[preview.nr-1]}}):false}>
                            <MdNavigateBefore/>
                        </div>
                        <div className={"next"+(preview.nr === images.length-1 ? " disabled":"")}
                             onClick={() => preview.nr < images.length-1 ? this.setState({preview: {nr: preview.nr+1, image: images[preview.nr+1]}}):false}>
                            <MdNavigateNext/>
                        </div>
                    </div>:""
                }
                <div className="items">
                    <div className="left">
                        {loading ? <Loading/>:""}
                        <Scrollbars>
                            <div className="items-inner">
                                {items}
                            </div>
                        </Scrollbars>
                    </div>
                    <div className="right">
                        {this.state.uploadImages.length > 0 || this.state.deleting ? <Loading/>:""}
                        <label className="fileupload">
                            <div className="upload"><span>Datei-Upload</span></div>
                            <input type="file" multiple onChange={e => this.handleImageChange(e)}/>
                        </label>
                        <div id="drag-thumb"/>
                        <div className="thumbs">
                            <Scrollbars autoHide>
                                <div className="thumb-wrapper">
                                    <div className="thumbs-inner">
                                        {
                                            images && images.map((image, i) =>
                                                <div className={"thumb" +
                                                (dragOverId === i && dragStartId !== i ? " line" : "") +
                                                (dragOverId > dragStartId ? " down" : " up") +
                                                (dragStartId === i ? " dragging" : "")} key={"thumb-"+i}
                                                     draggable={true}
                                                     onDragStart={e => this.startDrag(e, i, image)}

                                                     onDragOver={(e) => {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         if (i !== dragOverId) this.setState({dragOverId: i})
                                                     }}
                                                     onDrop={(e) => this.reArrange(e)}

                                                     style={{backgroundImage: `url(${image})`}}
                                                     onClick={() => this.setState({preview: {nr: i, image: image}})}
                                                >
                                                    <div className="remove"
                                                         onClick={(e) => {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             this.removeImage(i, this.props.property.id)
                                                         }}><MdDelete/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Property;
