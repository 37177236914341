import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_de from "./languages/de/translation.json"
import translation_en from "./languages/en/translation.json"

const resources = {
    de: {
        translation: translation_de
    },
    en: {
        translation: translation_en
    }
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: ['de', 'en'],
    lng: "de",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    debug: false,
    appendNamespaceToCIMode: true,
    joinArrays: false,
    returnObjects: false,
    react: {
        wait: false, // set to true if you like to wait for loaded translations in every translated hoc
        nsMode: "default", // set it to fallback to let passed namespaces to translated hoc act as fallbacks
        useSuspense: false,
    },
});

export default i18n;
