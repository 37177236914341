import React from "react";
import axios from "axios";

const fields = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordRepeat: ""
}

class Register extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            user: fields,
            passwordCheck:{
                uppercase: false,
                lowerCase: false,
                number: false,
                special: false
            },
            error: false
        }
    }

    addUser() {
        const {user} = this.state;
        axios.post(`${this.props.apiPath}`, {
            type: "adduser", data: {
                ...user
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if (res.data === "done") {
                    this.props.showToast("success", "Erfolg", "Ihr Benutzerkonto wurde erstellt. Bitte warten Sie auf die Freischaltung durch einen Administrator.")
                    this.props.updatePage("login");
                }
            })
            .catch(error => this.props.showToast("error", "Fehler", "Es ist ein Fehler beim Erstellen Ihres Benutzerkontos aufgetreten."));
    };

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    checkPassword(str) {
        return {
            lowerCase: !(/[a-z]/.test(str)),
            upperCase: !(/[A-Z]/.test(str)),
            number: !(/[0-9]/.test(str)),
            special: !(/[.,\-_\/#+&?!$]/.test(str)),
            length: str.length < 8
        };
    }

    async handleChange(e, field){
        await this.setState({
            user: {
                ...this.state.user,
                [field]: e.currentTarget.value
            },
            error: false
        })
    }

    submit(){
        const {user} = this.state;
        const check = Object.values(this.checkPassword(user.password)).filter(x => x === true)
        const empty = Object.values(user).filter(x => x.length === 0);
        const email = this.validateEmail(user.email);
        let error = false;
        if(user.password !== user.passwordRepeat){
            error = "Passwörter stimmen nicht überein."
        }else if(empty.length > 0){
            error = "Alle Felder ausfüllen."
        }else if(check.length > 0){
            error = "Passwort-Richtlinien beachten."
        }else if(!email){
            error = "Gültige E-Mail Adresse eingeben."
        }else{
            this.addUser();
        }
        this.setState({
            error: error
        })
    }

    render(){
        const {user, error} = this.state;
        const check = this.checkPassword(user.password);
        return(
            <div className="form-wrapper">
                <div className="form">
                    <h1>Registrieren</h1>
                    { error ? <p className="red">{error}</p>:""}
                    {
                        Object.keys(fields).map(f =>
                            <label key={f}>
                                <span>{f}</span>
                                <input type={this.props.getType(f)} name={f} value={user[f]} onChange={e => this.handleChange(e, f)}/>
                            </label>
                        )
                    }
                    <p>Passwort enthält</p>
                    <ul>
                        <li className={user.password !== "" && check.length ? "red":user.password !== ""?"green":""}>min. 8 Zeichen</li>
                        <li className={user.password !== "" && check.lowerCase ? "red":user.password !== ""?"green":""}>Kleinbuchstaben</li>
                        <li className={user.password !== "" && check.upperCase ? "red":user.password !== ""?"green":""}>Großbuchstaben</li>
                        <li className={user.password !== "" && check.number ? "red":user.password !== ""?"green":""}>Zahlen</li>
                        <li className={user.password !== "" && check.special ? "red":user.password !== ""?"green":""}>Sonderzeichen .,-_/#+&?!$</li>
                    </ul>
                    <div className="button-group">
                        <button className="light" onClick={() => this.props.updatePage("login")}>Abbrechen</button>
                        <button onClick={() => this.submit()}>Registrieren</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register;
