import React from 'react';
import Content from "./components/Content"
import Register from "./components/Register"
import Login from "./components/Login"

const local = false;
const API_PATH = local ? 'https://localhost/db/db.php?data=' : './db/db.php?data=';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            page: local ? "content":"login",
            toast: {
                type: false, title: "erfolg", text: "gespeichert"
            },
            user: false
        }
    }
    getType(f){
        switch (f){
            case "password":
            case "passwordRepeat":
                return "password";
            case "email":
                return "email";
            default:
                return "text"
        }
    }

    showToast(type, title, text) {
        this.setState({
            toast: {
                type: type, title: title, text: text
            }
        })
        setTimeout(() => {
            this.setState({
                toast: {
                    type: false
                }
            })
        }, 5000);
    }

    updatePage(page){
        this.setState({
            page: page
        })
    }
    getContent(){
        const props = {
            updatePage: page => this.updatePage(page),
            getType: f => this.getType(f),
            showToast: (type, title, text) => this.showToast(type, title, text),
            apiPath: API_PATH
        }
        switch(this.state.page){
            case "login":
                return <Login {...props} login={(user) => this.setState({user: user, page: "content"})}/>
            case "register":
                return <Register {...props}/>
            case "content":
                return <Content {...props}/>
            default:
                return <div>Error</div>
        }
    }
    render(){
        const {toast} = this.state;
        return (
            <div className="main-wrapper">
                {toast && toast.type && (<div className="toast">
                    <p className={"title " + toast.type}>{toast.title}</p>
                    <p>{toast.text}</p>
                </div>)}
                {this.getContent()}
            </div>
        );
    }
}

export default App;
