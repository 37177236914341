import React from "react";
import axios from 'axios';
import {Scrollbars} from "react-custom-scrollbars";
import {MdSave, MdAdd, MdDelete, MdClose, MdCheck, MdMoreVert, MdKeyboardArrowDown} from 'react-icons/md';
import Loading from "../assets/Loading"

const _ = require('lodash');

const fixed = ["Titel"]

const emptyItem = {
    "name": "", "type": "text", "value":"fix", "active": true
};

class Configuration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configuration: [], loading: true, dragOverId: false, dragStartId: false
        };
    }

    componentDidMount() {
        this.fetchConfiguration();
    }

    setConfiguration() {
        const {configuration} = this.state;
        axios.post(`${this.props.apiPath}`, {
            type: "configuration", data: {
                ...configuration
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if (res.data === "done") {
                    this.props.showToast("success", "Erfolg", "Die Konfiguration wurde erfolgreich gespeichert.")
                }
            })
            .catch(error => this.props.showToast("error", "Fehler", "Es ist ein Fehler beim Speichern der Konfiguration aufgetreten."));
    };

    fetchConfiguration() {
        axios.get(`${this.props.apiPath}configuration`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                const data = JSON.parse(res.data[0].content);
                this.setState({
                    configuration: data, loading: false
                })
            })
            .catch(error => this.props.showToast("error", "Fehler", "Es ist ein Fehler beim Laden der Konfiguration aufgetreten."));
    };

    removeItem(nr) {
        const {configuration} = this.state;
        configuration.splice(nr, 1);
        this.setState({
            ...configuration
        })
    }
    deUmlaut(value){
        value = value.toLowerCase();
        value = value.replace(/ä/g, 'ae');
        value = value.replace(/ö/g, 'oe');
        value = value.replace(/ü/g, 'ue');
        value = value.replace(/ß/g, 'ss');
        value = value.replace(/ /g, '-');
        value = value.replace(/\./g, '');
        value = value.replace(/\//g, '');
        value = value.replace(/,/g, '');
        value = value.replace(/\(/g, '');
        value = value.replace(/\)/g, '');
        return value.trim();
    }

    updateItem(e, nr, title) {
        const {configuration} = this.state;
        if(title === "name"){
            configuration[nr]["key"] = this.deUmlaut(e.currentTarget.value).toLowerCase();
        }
        configuration[nr][title] = title === "active" ? e.currentTarget.checked : e.currentTarget.value;
        this.setState({
            ...configuration
        })
    }

    addItem() {
        const {configuration} = this.state;
        configuration.push(_.cloneDeep(emptyItem));
        this.setState({
            ...configuration
        })
    }

    arrayMove(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    startDrag(e, i) {
        const crt = e.nativeEvent.target.parentNode.cloneNode(true);
        crt.style.width = "100%";
        document.getElementById("drag-items").appendChild(crt);
        e.dataTransfer.setDragImage(crt, 0, 0);
        this.setState({dragStartId: i})
    }

    reArrange(e) {
        e.preventDefault();
        e.stopPropagation();
        const {configuration, dragStartId, dragOverId} = this.state;
        const newConfiguration = this.arrayMove(configuration, dragStartId, dragOverId);
        this.setState({
            configuration: newConfiguration, dragStartId: false, dragOverId: false
        })

    }

    render() {
        const {configuration, loading, dragOverId, dragStartId} = this.state;
        const items = configuration.map((item, i) => {
            return (<div className={"item" +
            (dragOverId === i && dragStartId !== i ? " line" : "") +
            (dragOverId > dragStartId ? " down" : " up") +
            (dragStartId === i ? " dragging" : "")} key={"item-" + i}
                         onDragOver={(e) => {
                             e.preventDefault();
                             e.stopPropagation();
                             if (i !== dragOverId) this.setState({dragOverId: i})
                         }}
                         onDrop={(e) => this.reArrange(e)}
                >
                    <div className="item-wrapper">
                        <div className={"rearrange"}
                             draggable={true}
                             onDragStart={e => this.startDrag(e, i)}
                        >
                            <MdMoreVert/>
                        </div>
                        <label className="full">
                            <input type="text" value={item.name} onChange={e => this.updateItem(e, i, "name")}/>
                        </label>
                        <label className="full">
                            <input type="text" value={item.key} readOnly={true}/>
                        </label>
                        <label className="select">
                            <select onChange={e => this.updateItem(e, i, "type")} value={item.type}>
                                <option value="text">Text (einzeilig)</option>
                                <option value="textarea">Text (mehrzeilig)</option>
                            </select>
                            <MdKeyboardArrowDown/>
                        </label>
                        <label className="select">
                            <select onChange={e => this.updateItem(e, i, "value")} value={item.value}>
                                <option value="fix">Fix</option>
                                <option value="prozent">Prozent</option>
                            </select>
                            <MdKeyboardArrowDown/>
                        </label>
                        <label className="unit">
                            <input type="text" value={item.unit} onChange={e => this.updateItem(e, i, "unit")}/>
                        </label>
                        <div className="controls">
                            <label className="checkbox">
                                <input type="checkbox" checked={item.active} onChange={e => fixed && !fixed.includes(item.name) ? this.updateItem(e, i, "active") : false}/>
                                <span className="check"><MdCheck/></span>
                                Aktiv
                            </label>
                            <button className={fixed && fixed.includes(item.name) ? "disabled" : ""} onClick={() => fixed && !fixed.includes(item.name) ? this.removeItem(i) : false}><MdDelete/></button>
                        </div>
                    </div>
                </div>)
        })
        return (<div className="config modal">
                <div className="head">
                    <div className="left">
                        <button onClick={() => this.addItem()}>Neues Element <MdAdd/></button>
                        <button onClick={() => this.setConfiguration()}>Speichern <MdSave/></button>
                    </div>
                    <div className="right">
                        <button onClick={() => this.props.closeConfig()}>Schließen <MdClose/></button>
                    </div>
                </div>
                <div className="items">
                    <div id="drag-items"/>
                    {loading ? <Loading/> : ""}
                    <Scrollbars>
                        <div className="items-inner">
                            {items}
                        </div>
                    </Scrollbars>
                </div>
            </div>)
    }

}

export default Configuration;
