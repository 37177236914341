import React from "react";
import { MdSearch } from 'react-icons/md';

class Search extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            search: ""
        }
    }
    runSearch(e){
        this.setState({
            search: e.target.value
        })
        this.props.changeSearch(e.target.value);
    }
    render(){
        const {search} = this.state;
        return (
            <div className="search">
                <div className="input">
                    <input type="text" value={search} onChange={e => this.runSearch(e)}/>
                    <button><MdSearch/></button>
                </div>
            </div>
        )
    }

}
export default Search;
