import React from "react";
import axios from "axios";

const fields = {
    email: "",
    password: "",
};

class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            user: fields
        }
    }

    login() {
        const {user} = this.state;
        axios.post(`${this.props.apiPath}`, {
            type: "login", data: {
                ...user
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if(res.data === "error"){
                    this.props.showToast("error", "Fehler", "Es ist ein Fehler bei der Anmeldung aufgetreten.")
                }else{
                    this.props.login(res.data);
                }
            })
            .catch(error => this.props.showToast("error", "Fehler", "Es ist ein Fehler bei der Anmeldung aufgetreten."));
    };

    handleChange(e, field){
        this.setState({
            user: {
                ...this.state.user,
                [field]: e.currentTarget.value
            }
        })
    };

    render(){
        const {user} = this.state;
        return(
            <div className="form-wrapper">
                <div className="form">
                    <h1>Login</h1>
                    {
                        Object.keys(fields).map(f =>
                            <label key={f}>
                                <span>{f}</span>
                                <input type={this.props.getType(f)} name={f} value={user[f]} onChange={e => this.handleChange(e, f)}/>
                            </label>
                        )
                    }
                    <div className="button-group">
                        <button className="light" onClick={() => this.props.updatePage("register")}>Registrieren</button>
                        <button onClick={() => this.login()}>Login</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;
