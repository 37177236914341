import React from "react";
import axios from "axios";
import Loading from "../assets/Loading";
import {Scrollbars} from "react-custom-scrollbars";
import {MdDelete} from "react-icons/md";

class Properties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            properties: []
        };
    }

    componentDidMount() {
        this.fetchProperties();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {alertResult, search} = this.props;
        if(alertResult && alertResult.type === "removeProperty" && alertResult.result && alertResult.data){
            this.removeProperty(alertResult.data)
        }
        if(this.props.search !== prevProps.search){
            this.fetchProperties(search);
        }
    }
    fetchProperties(search = "") {
        axios.get(`${this.props.apiPath}properties&search=${this.props.search}`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                const properties = res.data.map(x => (
                    {id: x.id, content: JSON.parse(x.content), image: x.image, active: parseInt(x.active)}
                ));
                this.setState({
                    properties: properties, loading: false
                })
            })
            .catch(error => this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Laden der Immobilien aufgetreten. ${error}`));
    };

    removeProperty(id) {
        axios.post(`${this.props.apiPath}`, {
            type: "removeProperty",
            data: {
                id: id
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if(res.data === "done"){
                    this.props.showToast("success", "Erfolg", "Die Immobilie wurde erfolgreich gelöscht.");
                    this.props.showAlert(false, false, false);
                    this.setState({
                        properties: this.state.properties.filter(x => x.id !== id)
                    })
                }
            })
            .catch(error => this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Löschen der Immobilien aufgetreten. ${error}`));
    }

    changeActive(id, active){
        axios.post(`${this.props.apiPath}`, {
            type: "setPropertyActive",
            data: {
                id: id,
                active: active * 1
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if(res.data === "done"){
                    if(active){
                        this.props.showToast("success", "Erfolg", "Die Immobilie wurde erfolgreich aktiviert.");
                    }else{
                        this.props.showToast("success", "Erfolg", "Die Immobilie wurde erfolgreich deaktiviert.");
                    }
                }
            })
            .catch(error => this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Löschen der Immobilien aufgetreten. ${error}`));

    }

    setActive(e, item, i){
        e.stopPropagation();
        const {properties} = this.state;
        properties[i].active = !item.active;
        this.changeActive(item.id, item.active);
        this.setState({
            properties: properties
        })
    }

    formatPrice(number){
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    getValues(content, values){
        const result = [];
        values.map(v => {
            if(content[v] && content[v].value)
                result.push(<p key={v}>
                    <span>{v}</span>
                    <span>{content[v].unit === "€" ? this.formatPrice(content[v].value) : content[v].value} {content[v].unit !== "€" ? content[v].unit:""}</span>
                </p>);
        })
        return result;
    }

    render() {
        const {properties, loading} = this.state;
        const items = Array.isArray(properties) && properties.map((item, i) => {
            const c = item.content;
            return (<div className="property" key={"item-" + item.id} onClick={() => this.props.editProperty(item.id)}>
                <div className={"active"+(item.active?" true":" false")} onClick={(e) => this.setActive(e, item, i)}><span/></div>
                <div className="image-wrapper" style={{backgroundImage: `url('${item.image}')`}}/>
                <div className="inner">
                    <h3>{c.titel.value}</h3>
                    <div className="infos">
                        {this.getValues(c, ["kaufpreis", "zimmer", "wohnflaeche", "grundstueck"])}
                    </div>
                </div>
                <div className="remove" onClick={(e) => {
                    e.stopPropagation();
                    this.props.showAlert("removeProperty", item.id);
                }}>
                    <MdDelete/>
                </div>
            </div>)
        })
        return (<div className="properties">
            {loading ? <Loading/> : ""}
            <Scrollbars>
                <div className="properties-inner">
                    {items}
                </div>
            </Scrollbars>
        </div>)
    }

}

export default Properties;
