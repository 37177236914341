import React from "react"
import {MdSettings, MdAdd} from 'react-icons/md';
import Search from "./Search";
import Configuration from "./Configuration";
import Property from "./Property";
import Properties from "./Properties";

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfig: false,
            time: new Date().toISOString(),
            editProperty: {
                show: false, type: "", id: null
            },
            alert: {
                show: false, type: false, result: false, data: false
            },
            search: ""
        }
        this.editProperty = this.editProperty.bind(this);
    }

    editProperty(type, id = null) {
        this.setState({
            editProperty: {
                show: true,
                type: type,
                id: id
            }
        })
    }

    showAlert(type, data, show = true){
        this.setState({
            alert:{
                show: show,
                type: type,
                result: false,
                data: data
            }
        })
    }

    setAlert(result){
        this.setState({
            alert:{
                ...this.state.alert,
                show: false,
                type: this.state.alert.type,
                result: result
            }
        })
    }


    render() {
        const {showConfig, editProperty, time, alert, search} = this.state;
        return (<div className="main">
            {alert && alert.show && (<div className="alert">
                <div className="inner">
                    <div>
                        {alert.type}
                    </div>
                    <div>
                        <button onClick={() => this.setAlert(true)}>OK</button>
                        <button onClick={() => this.setAlert(false)}>Abbrechen</button>
                    </div>
                </div>
            </div>)}

            <div className="content">
                <div className="header">
                    <div className="logo"/>
                    <div className="add" onClick={() => this.editProperty("add")}><MdAdd/></div>
                    <Search changeSearch={search => this.setState({search: search})}/>
                    <button onClick={() => this.setState({showConfig: true})}><MdSettings/></button>
                </div>
                {showConfig && <Configuration
                    apiPath={this.props.apiPath}
                    closeConfig={() => this.setState({showConfig: false, time: new Date().toISOString()})}
                    showToast={(type, title, text) => this.props.showToast(type, title, text)}
                />}
                {editProperty.show && <Property
                    apiPath={this.props.apiPath}
                    property={editProperty}
                    closeProperty={() => this.setState({editProperty: {show: false}, time: new Date().toISOString()})}
                    showToast={(type, title, text) => this.props.showToast(type, title, text)}
                />}
                <Properties
                    key={time}
                    apiPath={this.props.apiPath}
                    editProperty={(id) => this.editProperty("edit", id)}
                    showToast={(type, title, text) => this.props.showToast(type, title, text)}
                    showAlert={(type, data, show) => this.showAlert(type, data, show)}
                    alertResult={alert}
                    search={search}
                />
            </div>
        </div>)
    }
}

export default Content
